import React from "react";
import axios from "axios";
import { api_platform } from "../configs/api_routes";
import { refresh_access_token, get_refresh_token } from "../helpers/tokens";
import { version } from "../../../package.json";

const REQUEST_HEADER = { headers: { "x-front-version": version } };
const ControlsStateContext = React.createContext();
const ControlsDispatchContext = React.createContext();

async function get_user_details(dispatch, { email, customer_id }) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.get(`${api_platform}/customers/${customer_id}/users/${email}`, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    // console.log(response.data);
    dispatch({ type: "get_user_success", payload: response.data });
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      get_user_details(dispatch, { email, customer_id });
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function edit_user(dispatch, customer_id, user) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    // console.log("edit_user_request", user);
    const response = await axios.put(`${api_platform}/customers/${customer_id}/users`, user, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    // console.log("edit_user_success", response.data);
    dispatch({ type: "edit_user_success", payload: response.data });
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      edit_user(dispatch, customer_id, user);
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function delete_user_group(dispatch, customer_id, user_group) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.delete(`${api_platform}/customers/${customer_id}/user_groups/${user_group.uuid}/?output=list`, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    dispatch({ type: "delete_user_group_success", payload: response.data });
    return { success: response.data.user_group_deleted, message: "This user group is linked to users. It can't be delete with associated users" };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      edit_user_group(dispatch, customer_id, user_group);
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function edit_user_group(dispatch, customer_id, user_group) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.put(`${api_platform}/customers/${customer_id}/user_groups/?output=list`, user_group, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    dispatch({ type: "edit_user_group_success", payload: response.data });
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      edit_user_group(dispatch, customer_id, user_group);
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function create_user_group(dispatch, customer_id, user_group) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.post(`${api_platform}/customers/${customer_id}/user_groups/create?output=list`, user_group, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    dispatch({ type: "create_user_group_success", payload: response.data });
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      create_user_group(dispatch, customer_id, user_group);
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function create_user(dispatch, customer_id, user) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.post(`${api_platform}/customers/${customer_id}/users/create?output=list`, user, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    // console.log("create_user_success", response.data);
    dispatch({ type: "create_user_success", payload: response.data });
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      create_user(dispatch, customer_id, user);
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function get_user_groups(dispatch, customer_id) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.get(`${api_platform}/customers/${customer_id}/all_user_groups`, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    // console.log("get_user_groups_success", response.data);
    dispatch({ type: "get_user_groups_success", payload: response.data });
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      get_user_groups(dispatch, customer_id);
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function get_roles(dispatch, customer_id) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.get(`${api_platform}/customers/${customer_id}/roles`, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    // console.log("get_roles_success", response.data);
    dispatch({ type: "get_roles_success", payload: response.data });
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      get_roles(dispatch, customer_id);
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function get_users(dispatch, customer_id) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.get(`${api_platform}/customers/${customer_id}/users`, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    // console.log("get_users_success", response.data);
    dispatch({ type: "get_users_success", payload: response.data });

    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      get_users(dispatch, customer_id);
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function get_api_keys(dispatch, customer_id) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.get(`${api_platform}/customers/${customer_id}/api_keys`, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    // console.log("get_api_keys_success", response.data);
    dispatch({ type: "get_api_keys_success", payload: response.data });

    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      get_api_keys(dispatch, customer_id);
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function create_api_key(dispatch, customer_id) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.post(
      `${api_platform}/customers/${customer_id}/api_keys`,
      {},
      { headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token } }
    );
    // console.log("get_api_keys_success", response.data);
    dispatch({ type: "get_api_keys_success", payload: response.data });
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      create_api_key(dispatch, customer_id);
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function delete_api_key(dispatch, customer_id, api_key) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.delete(`${api_platform}/customers/${customer_id}/api_keys/${api_key.uuid}`, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    // console.log("get_api_keys_success", response.data);
    dispatch({ type: "get_api_keys_success", payload: response.data });

    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      delete_api_key(dispatch, customer_id, api_key);
      return;
    }
    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

function ControlsReducer(state, action) {
  switch (action.type) {
    case "init": {
      return { ...state, error: undefined, loading: false };
    }
    case "error": {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case "request_init": {
      return { ...state, loading: true };
    }
    case "create_user_success": {
      return { ...state, users: action.payload, loading: false };
    }
    case "create_user_group_success": {
      return { ...state, user_groups: action.payload, loading: false };
    }
    case "edit_user_group_success": {
      return { ...state, user_groups: action.payload, loading: false };
    }
    case "delete_user_group_success": {
      return { ...state, user_groups: action.payload.result, loading: false };
    }
    case "get_api_keys_success": {
      return { ...state, api_keys: action.payload, loading: false };
    }
    case "get_users_success": {
      return { ...state, users: action.payload, loading: false };
    }
    case "get_user_success": {
      return { ...state, user: action.payload, loading: false };
    }
    case "get_user_groups_success": {
      return { ...state, user_groups: action.payload, loading: false };
    }
    case "get_roles_success": {
      return { ...state, roles: action.payload, loading: false };
    }
    case "edit_user_success": {
      return { ...state, user: action.payload, loading: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AdminProvider({ children }) {
  const [state, dispatch] = React.useReducer(ControlsReducer, {
    loading: false,
    error: undefined,
    users: undefined,
    user_groups: undefined,
    user: undefined,
    api_keys: undefined,
  });
  return (
    <ControlsStateContext.Provider value={state}>
      <ControlsDispatchContext.Provider value={dispatch}>{children}</ControlsDispatchContext.Provider>
    </ControlsStateContext.Provider>
  );
}

function useStoreState() {
  const context = React.useContext(ControlsStateContext);
  if (context === undefined) {
    throw new Error("useStoreState must be used within a AdminProvider");
  }
  return context;
}

function useStoreDispatch() {
  const context = React.useContext(ControlsDispatchContext);
  if (context === undefined) {
    throw new Error("useStoreDispatch must be used within a AdminProvider");
  }
  return context;
}

export {
  AdminProvider,
  useStoreState,
  useStoreDispatch,
  get_users,
  get_user_details,
  create_user,
  edit_user,
  get_user_groups,
  edit_user_group,
  delete_user_group,
  create_user_group,
  get_roles,
  get_api_keys,
  create_api_key,
  delete_api_key,
};
