import React from "react";
import axios from "axios";
import { api_platform } from "../configs/api_routes";
import { refresh_access_token, get_refresh_token } from "../helpers/tokens";
import { version } from "../../../package.json";
const REQUEST_HEADER = { headers: { "x-front-version": version } };
const ControlsStateContext = React.createContext();
const ControlsDispatchContext = React.createContext();

async function get_customer_permissions(dispatch, { customer_id, user_email }) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.get(`${api_platform}/customers/${customer_id}/permissions`, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    // console.log(response);
    dispatch({ type: "get_customer_permissions_success", payload: response.data });
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: JSON.stringify(error) };
    }
    let token_refreshed = undefined;
    if (error.response.status === 401) {
      token_refreshed = await refresh_access_token();
      if (!token_refreshed) {
        const new_refresh_token = await get_refresh_token();
        if (!new_refresh_token) {
          window.location.reload();
        }
      }
      get_customer_permissions(dispatch, { customer_id, user_email });
      return {};
    } else if (error.response.status === 412) {
      let message = "Version mismatch. Please reload or contact an administrator at support@ignifai.net";
      dispatch({ type: "error", payload: message });
      return { success: false, message: message };
    } else if (error.response.status === 500) {
    }

    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

async function get_user_groups(dispatch, customer_id) {
  const token = localStorage.getItem("token");
  dispatch({ type: "request_init" });
  try {
    const response = await axios.get(`${api_platform}/customers/${customer_id}/all_user_groups`, {
      headers: { ...REQUEST_HEADER.headers, Authorization: "Bearer " + token },
    });
    // console.log(response);
    dispatch({ type: "request_user_groups_success", payload: response.data });
    return { success: true };
  } catch (error) {
    if (!error.response || !error.response.status) {
      dispatch({ type: "error", payload: error.message });
      return { success: false, message: error.message };
    }

    let message = error.response.data.message;
    dispatch({ type: "error", payload: message });
    return { success: false, message: message };
  }
}

function ControlsReducer(state, action) {
  switch (action.type) {
    case "init": {
      return { ...state, error: undefined, loading: false };
    }
    case "error": {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case "request_init": {
      return { ...state, loading: true };
    }
    case "request_user_groups_success": {
      return { ...state, user_groups: action.payload, loading: false };
    }
    case "get_customer_permissions_success": {
      let permissions_list = action.payload.permissions;
      let permissions = {};

      permissions_list.forEach((permission) => {
        let app = permission.split("-")[0];
        if (!permissions[app]) {
          permissions[app] = { name: app, list: [] };
        }
        permissions[app].list.push(permission.split("-")[1]);
      });
      if (action.payload.customer) {
        localStorage.setItem("last_customer", JSON.stringify(action.payload.customer));
      }
      return { ...state, loading: false, active_customer: action.payload.customer, permissions };
    }
    case "logout": {
      return { ...state, active_customer: undefined, permissions: undefined };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CustomerProvider({ children }) {
  const [state, dispatch] = React.useReducer(ControlsReducer, {
    loading: false,
    error: undefined,
    active_customer: undefined,
    permissions: undefined,
    user_groups: undefined,
  });
  return (
    <ControlsStateContext.Provider value={state}>
      <ControlsDispatchContext.Provider value={dispatch}>{children}</ControlsDispatchContext.Provider>
    </ControlsStateContext.Provider>
  );
}

function useStoreState() {
  const context = React.useContext(ControlsStateContext);
  if (context === undefined) {
    throw new Error("useStoreState must be used within a CustomerProvider");
  }
  return context;
}

function useStoreDispatch() {
  const context = React.useContext(ControlsDispatchContext);
  if (context === undefined) {
    throw new Error("useStoreDispatch must be used within a CustomerProvider");
  }
  return context;
}

export { CustomerProvider, useStoreState, useStoreDispatch, get_customer_permissions, get_user_groups };
