import React from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

const CenteredDiv = styled.div`
  width: 100% !important;
  min-height: 720px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function LargeSpinner() {
  return (
    <CenteredDiv className="w-100 text-center">
      <Spinner />
    </CenteredDiv>
  );
}

export default LargeSpinner;
