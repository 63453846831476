import React from "react";
import { Link } from "react-router-dom";
import "boxicons";

const DropDownIconTextItem = (props) => {
  const { type, title, icon, link } = props;
  if (!link || link.trim() === "") {
    return (
      <span {...props} className="d-flex p-1 align-items-center">
        <box-icon name={icon} type={type} size="20px" className="mr-4" color="black"></box-icon>
        <span className="ml-2">{title}</span>
      </span>
    );
  }
  return (
    <Link to={link} className="d-flex p-1 align-items-center">
      <box-icon name={icon} type={type} size="20px" className="mr-4" color="black"></box-icon>
      <span className="ml-2">{title}</span>
    </Link>
  );
};

export default DropDownIconTextItem;
