import { createGlobalStyle } from "styled-components";
import theme from "../theme";

const GlobalStyle = createGlobalStyle`
 @import url('https://fonts.googleapis.com/css?family=Roboto:100,400,500,700');
  html, body, #root {
    width: 100%;
    max-height: 100vh;
  }

  body {
    background-color: ${theme.colors.lightgrey};
    font-family: 'Roboto', sans-serif;
    font-size: 1em; 
    margin: 0;
    box-sizing: border-box;
    color: ${theme.colors.white};
    overflow: auto;
    max-height: 100vh;
  }

  a:link, a:visited {
    color: inherit!important;
    text-decoration: none;
  }

  a:hover, a:active {
    color: inherit!important;;
    text-decoration: underline;
  }

  .modal-backdrop.show {
    opacity: .8;
  }
`;

export default GlobalStyle;
