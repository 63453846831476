import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStoreState } from "../utils/stores/AuthStore";

const PrivateRoute = ({ component: Component, currentAccess, access, admin, ...rest }) => {
  const { isAuthenticated } = useStoreState();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: "/auto-login", state: { from: props.location } }} />
      }
    />
  );
};

export default PrivateRoute;
