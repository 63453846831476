const colors = {
  accent: '#0B0132',
  primary:"#FB0E8B",
  secondary:"#FA246A",
  red:"#FB3A4C",
  white: "#FFFFFF",
  grey: "#E4E4E4",
  charcoal: "#4A4A4A",
  black: "#000000",
  orange: "#FF9900",
  orange2: "#F5A623",
  orange2_dark: "#9b6407",
  nobel: "#9B9B9B",
  anothergrey: "#7D7D7D",
  lightgrey: "#CCCCCC",
  dd: "#DDD",
  verylight: "#AAAAAA",
  nero: "#191919",
  pink: "#FB0E8B",
};

const theme = {
  colors,
};

export default theme;
