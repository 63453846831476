import { Dropdown, Menu } from "antd";
import "boxicons";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse, DropdownItem, Nav, Navbar, NavItem } from "reactstrap";
import styled from "styled-components";
import { title, version } from "../../../package.json";
import DropDownIconTextItem from "../../technical_components/DropDownIconTextItem";
import logo from "../../utils/assets/logo.svg";
import { get_api_infos, useStoreDispatch as useAuthStoreDispatch, useStoreState as useAuthStoreState } from "../../utils/stores/AuthStore";
import { useStoreState as useCustomerStoreState } from "../../utils/stores/CustomerStore";
import theme from "../../utils/styles/theme";

const HeaderStyle = styled.div`
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  background-color: ${theme.colors.accent};
  color: ${theme.colors.white};
  box-sizing: border-box;
  z-index: 900 !important;
`;

const HeaderImage = styled.img`
  height: 50px;
`;

const HeaderTitle = styled.div`
  font-size: 36px !important;
  margin: 0 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 300;
  line-height: 56px;
  padding: 0.5rem 1rem;
`;

const LinkLight = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const Pointy = styled.div`
  cursor: pointer;
`;

const SmallText = styled.small`
  display: inline-block;
  justify-content: center;
  width: 100%;
  padding: 0.6rem;
  font-size: 14px;
  color: #999;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;
const UserContainer = styled.div`
  min-width: 10rem;
  max-width: 15rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;
`;
const UserInfos = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 0.75rem;
`;
const Useremail = styled.span`
  color: #ffffff88;
  font-size: 0.751rem;
`;
const Username = styled.h4`
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 1rem;
`;
const DynaLink = (url, child) => {
  const thing_type = typeof url;
  if (thing_type === "string") {
    if (url.startsWith("http")) {
      return <a href={url}>{child}</a>;
    } else {
      return <LinkLight to={url}>{child}</LinkLight>;
    }
  }
  return <Pointy onClick={url}>{child}</Pointy>;
};

const Header = () => {
  const { isAuthenticated, user, customers, api_version, loading, error } = useAuthStoreState();
  const dispatch = useAuthStoreDispatch();
  const { active_customer } = useCustomerStoreState();

  useEffect(() => {
    get_api_infos(dispatch);
  }, []);
  useEffect(() => {}, [active_customer]);
  const specialUser = customers && customers.length > 1;

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <DropDownIconTextItem title="Personnal Settings" link="/profile" icon="user"></DropDownIconTextItem>
      </Menu.Item>
      <Menu.Item key="1">
        <DropdownItem header>{active_customer?.name?.toUpperCase()}</DropdownItem>
      </Menu.Item>
      {specialUser && (
        <Menu.Item key="1.1">
          <DropDownIconTextItem title="Change customer account" link="/customers/switch" icon="refresh"></DropDownIconTextItem>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="2">
        <DropDownIconTextItem title="Logout" link="/logout" icon="log-out"></DropDownIconTextItem>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <SmallText>
          GUI version {version}
          <br />
          API version {api_version}
        </SmallText>
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderStyle className="px-5 header-bloc ">
      <Navbar expand="xs" className="p-0 navbar-dark">
        {DynaLink(window.location.origin, <HeaderImage className="mr-2 " src={logo} alt="Logo" />)}
        <Collapse navbar>
          <Nav className="mr-auto" navbar>
            <NavItem tag="div">
              <div className="text-white d-flex align-items-center">
                <StyledLink to="/">
                  <HeaderTitle>{title}</HeaderTitle>
                </StyledLink>
              </div>
            </NavItem>
          </Nav>
          {!isAuthenticated && <span className="text-light">Version {version}</span>}
          {isAuthenticated && (
            <Nav className="ml-auto" navbar>
              {user && (!loading || !error) && (
                <Dropdown overlay={menu} trigger={["click"]}>
                  <UserContainer>
                    <UserInfos>
                      <Username>{user.name}</Username>
                      <Useremail>{active_customer?.name}</Useremail>
                    </UserInfos>
                    <box-icon type="solid" name="chevron-down" color="#ffffff66" size="20px"></box-icon>
                  </UserContainer>
                </Dropdown>
              )}
            </Nav>
          )}
        </Collapse>
      </Navbar>
    </HeaderStyle>
  );
};

export default Header;
